
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import commonTabClasses from '../../styles/index.module.scss';
import Tab2Image from '@/images/home/how-does-smsapi-work-tab2.png';
import Button from '@/components/common/Button';
import { H2, P } from '@/components/common/Typography';
const PanelTab: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { t, lang } = useTranslation('home');
    const localesWithoutVideoGuidePage = ['el'];
    return (<div className={commonTabClasses.layout}>
      <div className={commonTabClasses.leftSide}>
        <H2 className={commonTabClasses.h2}>{t('Customer Portal')}</H2>
        <P className={commonTabClasses.paragraph}>
          {t('Send SMS, check numbers in the HLR database and receive SMSs from customers directly in the customer portal. A professional SMS gateway accessible from the website is the easiest and fastest way to handle company mobile communication.')}
        </P>
        {!localesWithoutVideoGuidePage.includes(lang) && (<Button className={commonTabClasses.button} href="/video-guide" label={t('Learn more')} buttonType="primary" icon={{ iconName: 'arrow', placeAfterLabel: true }}/>)}
      </div>
      <div className={commonTabClasses.rightSide}>
        <Image src={Tab2Image} alt={t('Customer Portal') + ' - SMSAPI'} style={{ height: 'auto' }} quality={90} draggable={false}/>
      </div>
    </div>);
};
export default PanelTab;

    async function __Next_Translate__getStaticProps__19192d94e52__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//Home/components/HowDoesSmsapiWork/components/Tabs/components/PanelTab/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19192d94e52__ as getStaticProps }
  