
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { Suspense, useContext, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import clsx from 'clsx';
import commonTabStyles from '../../styles/index.module.scss';
import { CodeExamples } from '../../../../types';
import classes from './index.module.scss';
import { Loader } from '@/components/common/Loader';
import { theme } from '@/styles/theme';
import ConfigsContext from '@/contexts/configs';
import useMediaQuery from '@/hooks/useMediaQuery';
import { BreakpointName } from '@/styles/constants/breakpoints/types';
import useAppSWR from '@/hooks/useAppSWR';
import Button from '@/components/common/Button';
import { H2, P } from '@/components/common/Typography';
const CodeExample = dynamic(() => import('./CodeExample'), {
    suspense: true,
    ssr: false,
});
interface Props {
    shouldLoadCodeExamples: boolean;
}
const ApiTab: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ shouldLoadCodeExamples, }) => {
    const { t } = useTranslation('home');
    const { libraries } = useContext(ConfigsContext);
    const visibleLanguages = ['PHP', 'Python', 'JavaScript'];
    const singleExampleKeyCode = 'SINGLE_EXAMPLE';
    const isBelowMdBreakpoint = useMediaQuery({ lessThan: BreakpointName.Md });
    const options = libraries.official.filter((library) => visibleLanguages.includes(library.language));
    const { data: codeExamples, isLoading: isLoadingCodeExamples } = useAppSWR<CodeExamples>(shouldLoadCodeExamples ? { url: '/api/next/code-examples' } : null);
    const [activeTab, setActiveTab] = useState(options[0]);
    const activeTabCodeKey = activeTab.codeExamples.find((codeExamples) => codeExamples.codeKey.includes(singleExampleKeyCode))?.codeKey;
    return (<div className={commonTabStyles.layout}>
      <div className={commonTabStyles.leftSide}>
        <H2 className={commonTabStyles.h2}>{t('API')}</H2>
        <P className={commonTabStyles.paragraph}>
          {t("Explore our open and secure SMS API, see code examples, and programme your SMS messaging in any system or application. With detailed documentation and libraries, you'll benefit from every feature of SMS API and customise it to your needs.")}
        </P>
        <Button className={commonTabStyles.button} href="/sms-api" label={t('how-does-smsapi-work-api-more-button')} buttonType="primary" icon={{ iconName: 'arrow', placeAfterLabel: true }}/>
      </div>
      <div className={commonTabStyles.rightSide}>
        <div className={classes.outerWrapper}>
          <div className={classes.innerWrapper}>
            <div className={classes.tabsLayout}>
              {options.map((option) => (<button className={clsx(classes.tab, option.language === activeTab.language &&
                classes.tab_active)} key={option.language} onClick={() => setActiveTab(option)}>
                  {option.language}
                </button>))}
            </div>
            <div className={classes.codeExamplesLayout}>
              <Suspense fallback={<Loader className={classes.loader}/>}>
                {shouldLoadCodeExamples && (<>
                    {isLoadingCodeExamples && (<Loader className={classes.loader}/>)}
                    <CodeExample activeTab={activeTab} styles={isBelowMdBreakpoint
                ? {
                    padding: 0,
                    fontSize: theme.font.size.xs,
                }
                : undefined}>
                      {activeTabCodeKey &&
                !isLoadingCodeExamples &&
                codeExamples?.[activeTabCodeKey]}
                    </CodeExample>
                  </>)}
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </div>);
};
export default ApiTab;

    async function __Next_Translate__getStaticProps__19192d94e5d__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//Home/components/HowDoesSmsapiWork/components/Tabs/components/ApiTab/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19192d94e5d__ as getStaticProps }
  